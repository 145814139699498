import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFileDownloader } from "../../utils/useFileDownloader";

import { getDownloadURL } from "firebase/storage";
import useTrack from "../../hooks/useTrack";
import { setIsPlaying, setTrack } from "../../redux/audioplayer/actions";
import { selectAudioPlayerState } from "../../redux/audioplayer/selectors";
import { getStorageRefFromURI, getThumbnailBlobURL } from "../../utils/Helpers";

const AudioPlayer = React.memo(() => {
  const { currentIsrc, currentUPC, isPlaying } = useSelector(
    selectAudioPlayerState()
  );
  const dispatch = useDispatch();
  const { getAuthorizedAudioUrl } = useFileDownloader();
  const [audioUrl, setAudioUrl] = useState<string | undefined>();
  const { track, getTrack } = useTrack();
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>();

  const fetchAudioUrl = async () => {
    if (currentIsrc) {
      try {
        const url = await getAuthorizedAudioUrl(currentIsrc);
        setAudioUrl(url);
        getTrack(currentIsrc);
      } catch (error) {
        // Handle the error appropriately
        console.error("Failed to fetch audio URL:", error);
      }
    }
  };

  useEffect(() => {
    const thumbnailUrl = getThumbnailBlobURL(currentUPC);
    const ref = getStorageRefFromURI(thumbnailUrl);
    getDownloadURL(ref)
      .then((url) => {
        setThumbnailUrl(url);
      })
      .catch((error) => {});
  }, [currentUPC]);

  useEffect(() => {
    fetchAudioUrl();
  }, [currentIsrc]);

  if (!track || !isPlaying) {
    return null;
  }

  return (
    <div className="bottom-4 right-6 fixed z-50 p-2 rounded-lg bg-neutral-background flex flex-col group  shadow-neutral-heavy shadow-sm">
      <div className="flex items-center w-full">
        <img
          className="object-cover rounded-full w-16 h-16 bg-transparent border-2"
          alt="thumbnail"
          src={thumbnailUrl}
          loading="eager"
          width={60}
          height={60}
        />
        <div className="flex flex-col pl-4 text-neutral-semiheavy text-sm">
          <p className="font-semibold">{track.primaryArtists[0].artistName}</p>
          <p className="mb-2">{track.title}</p>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1.8rem"
          viewBox="0 0 512 512"
          className="ml-auto self-start hover:cursor-pointer rounded-full transform hover:scale-110"
          onClick={() => {
            dispatch(setTrack(undefined));
            dispatch(setIsPlaying(false));
          }}
        >
          <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
        </svg>
      </div>
      <audio controls autoPlay={isPlaying} src={audioUrl} className="w-96" />
    </div>
  );
});

export default AudioPlayer;
