/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { InjectedProps, connect } from "react-redux";
import { createSelector } from "reselect";
import * as Yup from "yup";
import axiosClient from "../../../clients/AxiosClient";
import { LANGUAGES, RELEASE_FORMATS } from "../../../config/Constants";
import useProduct from "../../../hooks/useProduct";
import * as globalMessageActions from "../../../redux/globalmessages/actions";
import * as productActions from "../../../redux/product/actions";
import { selectProductState } from "../../../redux/product/selectors";
import { Artist, Product } from "../../../types/ProductTypes";
import { TrackInfo } from "../../../types/TrackTypes";
import SimpleField from "../../SimpleField";
import { AwSection } from "../../awcomponents/AwSection";
import { InputFieldGroup } from "../../inputs/InputFieldGroup";
import SearchableDropDown from "../../searchabledropdown/SearchableDropDown";
import SearchableDropDownAsync from "../../searchabledropdown/SearchableDropDownAsync";
import { AddTolistButton, RemoveFromListButton } from "../FormUtils";
import AuthorsDisplay from "./AuthorsDisplay";

export const emptyProduct: Product = {
  upc: 0,
  name: "",
  primaryArtists: [
    {
      artistName: "",
      restricted: false,
      spotifyArtistUri: undefined,
      conceptArtist: false,
    },
  ],
  featuredArtists: [
    {
      artistName: "",
      restricted: false,
      spotifyArtistUri: undefined,
      conceptArtist: false,
    },
  ],
  catalogNumber: "",
  originalReleaseDate: new Date().toLocaleDateString("en-SE"),
  mainGenre: "New Age",
  subGenre: "",
  languageOfMetadata: "en",
  label: "",
  pLineText: "Copyright - label",
  pLineYear: new Date().getFullYear(),
  cLineText: "Copyright - label",
  cLineYear: new Date().getFullYear(),
  releaseFormatType: "SINGLE",
  tracks: [],
  productVersion: "",
  releaseDates: {
    upc: 0,
    spotifyReleaseDate: undefined,
    amazonReleaseDate: undefined,
    youtubeReleaseDate: undefined,
    youtubeCIDReleaseDate: undefined,
    x5ReleaseDate: undefined,
  },
  dspSpecificLabels: undefined,
};

export const emptyArtist = (): Artist => ({
  artistId: -1,
  artistName: "",
  restricted: false,
  spotifyArtistUri: undefined,
  conceptArtist: false,
});

const setDefaultUpcValues = (values: Product) => ({
  ...values,
  releaseDates: {
    ...values.releaseDates,
    upc: values.upc,
  },
  dspSpecificLabels: {
    ...values.dspSpecificLabels,
    upc: values.upc,
  },
});

const withRedux = connect(
  createSelector(selectProductState(), (product) => ({
    productState: product,
  })),
  {
    addGlobalMessage: globalMessageActions.addMessage,
    closeMessage: globalMessageActions.closeMessage,
    setProduct: productActions.setProduct,
    setTracks: productActions.setTracks,
    resetForm: productActions.resetProductForm,
  }
);

const ProductForm = React.memo(
  (
    props: InjectedProps<typeof withRedux> & {
      onSubmit: () => void;
      onClose: () => void;
    }
  ) => {
    const { saveProduct } = useProduct();

    const validationSchema = Yup.object().shape({
      upc: Yup.number()
        .typeError("Must be a number")
        .min(100000000000, "Too short! UPC has to be 12 characters")
        .required("This field is required"),
      catalogNumber: Yup.string().required("This field is required"),
      name: Yup.string().required("This field is required"),
      primaryArtists: Yup.array().required(
        "At least 1 primary artist is required"
      ),
    });

    const aggregateTrackList = (): TrackInfo[] =>
      props.productState.tracks
        .filter((track) => track.isrc !== "")
        .map((track, index) => {
          return {
            isrc: track.isrc,
            title: track.title,
            //Important, Human form.
            sequenceNumber: index + 1,
            onDisc: 1,
          };
        });

    return (
      <Formik<Product>
        validationSchema={validationSchema}
        initialValues={props.productState.product}
        enableReinitialize
        initialStatus={{ success: undefined, message: undefined }}
        onSubmit={(values: Product, actions: FormikHelpers<Product>) => {
          values.tracks = aggregateTrackList();
          const updatedValues = setDefaultUpcValues(values);
          saveProduct(updatedValues);

          props.setProduct(values);
          actions.setSubmitting(false);
          props.onSubmit();
        }}
      >
        {({ values, isSubmitting, setFieldValue, errors, isValid }) => (
          <Form className="w-full">
            <AwSection>
              <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                <SimpleField
                  type="number"
                  name="upc"
                  label="UPC"
                  error={errors.upc}
                  disabled={true}
                />
                <SimpleField
                  type="text"
                  name="catalogNumber"
                  label="Catalog number"
                  error={errors.catalogNumber}
                />
                <SimpleField
                  type="text"
                  name="name"
                  label="Album title"
                  error={errors.name}
                />
                <SimpleField
                  type="text"
                  name="productVersion"
                  label="Release version"
                />
                <FieldArray
                  name="primaryArtists"
                  render={(arrayHelpers) => (
                    <InputFieldGroup title="Primary Artists">
                      {errors.primaryArtists && (
                        <p className="text-error">
                          {errors.primaryArtists.toString()}
                        </p>
                      )}
                      {values.primaryArtists.map((artist, index) => (
                        <div className="flex items-center gap-x-2">
                          <SearchableDropDownAsync<Artist>
                            initialValue={artist}
                            fetchFunction={(search) =>
                              axiosClient
                                .get<Artist[]>("/artists", {
                                  params: { search },
                                })
                                .then((res) => res.data)
                            }
                            placeHolder="Select artist"
                            getOptionLabel={(p) => p.artistName}
                            getOptionValue={(p) => p.artistId!!.toString()}
                            onSelect={(a) =>
                              setFieldValue(`primaryArtists.${index}`, a)
                            }
                          />

                          <RemoveFromListButton
                            arrayHelpers={arrayHelpers}
                            index={index}
                          />
                        </div>
                      ))}
                      <AddTolistButton
                        arrayHelpers={arrayHelpers}
                        emptyObject={emptyArtist()}
                      />
                    </InputFieldGroup>
                  )}
                />

                <FieldArray
                  name="featuredArtists"
                  render={(arrayHelpers) => (
                    <div>
                      <InputFieldGroup title="Featured Artists">
                        {values.featuredArtists?.map((artist, index) => (
                          <div className="flex items-center gap-x-2">
                            <SearchableDropDownAsync<Artist>
                              fetchFunction={(search) =>
                                axiosClient
                                  .get<Artist[]>("/artists", {
                                    params: { search },
                                  })
                                  .then((res) => res.data)
                              }
                              placeHolder="Select artist"
                              getOptionLabel={(p) => p.artistName}
                              getOptionValue={(p) => p.artistId!!.toString()}
                              initialValue={artist}
                              onSelect={(a) =>
                                setFieldValue(`featuredArtists.${index}`, a)
                              }
                            />
                            <RemoveFromListButton
                              arrayHelpers={arrayHelpers}
                              index={index}
                            />
                          </div>
                        ))}
                        <AddTolistButton
                          arrayHelpers={arrayHelpers}
                          emptyObject={emptyArtist()}
                        />
                      </InputFieldGroup>
                    </div>
                  )}
                />

                <SearchableDropDown
                  options={RELEASE_FORMATS.map((relFormat) => ({
                    value: relFormat,
                    name: relFormat,
                  }))}
                  initialValue={{
                    value: values.releaseFormatType,
                    name: values.releaseFormatType,
                  }}
                  isFormField
                  getOptionValue={(val) => val.value}
                  getOptionLabel={(val) => val.name}
                  label="Release Format"
                  name="releaseFormatType"
                  onSelect={(value) =>
                    setFieldValue("releaseFormatType", value.value)
                  }
                />

                <SimpleField type="text" name="label" label="Label" />
                <SearchableDropDown
                  options={LANGUAGES}
                  isFormField
                  initialValue={{
                    name:
                      LANGUAGES.find(
                        (lang) =>
                          lang.code === values.languageOfMetadata?.toLowerCase()
                      )?.name || values.languageOfMetadata,
                    code: values.languageOfMetadata,
                  }}
                  name="languageOfMetadata"
                  label="Metadata Language"
                  getOptionValue={(val) => val.code || ""}
                  getOptionLabel={(val) => val.name || ""}
                  onSelect={(value) =>
                    setFieldValue("languageOfMetadata", value.code)
                  }
                />

                <SimpleField type="text" name="pLineText" label="P Line" />
                <SimpleField type="number" name="pLineYear" label="P Year" />
                <SimpleField type="text" name="cLineText" label="C Line" />
                <SimpleField type="number" name="cLineYear" label="C Year" />

                <SimpleField
                  type="text"
                  name="originalReleaseDate"
                  label="Original Release date"
                />
              </div>
            </AwSection>
            <AwSection title="Release Dates">
              <div className="flex flex-wrap gap-8 justify-between">
                <SimpleField
                  className="flex-1"
                  name="releaseDates.spotifyReleaseDate"
                  label="Spotify"
                  type="date"
                />
                <SimpleField
                  className="flex-1"
                  name="releaseDates.amazonReleaseDate"
                  label="Amazon"
                  type="date"
                />
                <SimpleField
                  className="flex-1"
                  name="releaseDates.youtubeReleaseDate"
                  label="YouTube"
                  type="date"
                />
                <SimpleField
                  className="flex-1"
                  name="releaseDates.youtubeCIDReleaseDate"
                  label="YouTube Content ID"
                  type="date"
                />

                <SimpleField
                  className="flex-1"
                  name="releaseDates.x5ReleaseDate"
                  label="X5"
                  type="date"
                />
              </div>
            </AwSection>
            <AwSection title="Labels">
              <div className="flex flex-wrap gap-8 justify-between">
                <SimpleField
                  className="flex-1 min-w-1/5"
                  name="dspSpecificLabels.spotifyLabel"
                  label="Spotify"
                  type="text"
                  placeholder={values.label}
                />
                <SimpleField
                  className="flex-1 min-w-1/5"
                  name="dspSpecificLabels.amazonLabel"
                  label="Amazon"
                  type="text"
                  placeholder={values.label}
                />
                <SimpleField
                  className="flex-1 min-w-1/5"
                  name="dspSpecificLabels.youtubeLabel"
                  label="YouTube"
                  type="text"
                  placeholder={values.label}
                />
                <SimpleField
                  className="flex-1 min-w-1/5"
                  name="dspSpecificLabels.youtubeCIDLabel"
                  label="YouTube Content ID"
                  type="text"
                  placeholder={values.label}
                />
                <SimpleField
                  className="flex-1 min-w-1/5"
                  name="dspSpecificLabels.x5Label"
                  label="X5"
                  type="text"
                  placeholder={values.label}
                />
              </div>
            </AwSection>

            <div className="flex gap-x-4  justify-between">
              <AuthorsDisplay product={values} />
              <div className="flex gap-x-4 justify-end pb-8">
                <button
                  type="button"
                  className="secondary small"
                  onClick={props.onClose}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="primary small"
                  disabled={!isValid || isSubmitting}
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
);

export default withRedux(ProductForm);
