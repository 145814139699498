import { gql } from "@apollo/client";

export const GET_PRODUCTS_QUERY = gql`
  query getProducts($page: Long!, $searchQuery: String) {
    products(page: $page, searchQuery: $searchQuery) {
      total
      content {
        upc
        name
        originalReleaseDate
        primaryArtists {
          artistId
          artistName
          restricted
        }
        tracks {
          isrc
          title
          sequenceNumber
        }
      }
    }
  }
`;

export const GET_PRODUCT_QUERY = gql`
  query getProduct($upc: Long!) {
    product(upc: $upc) {
      upc
      name
      catalogNumber
      cLineText
      cLineYear
      pLineText
      pLineYear
      label
      mainGenre
      subGenre
      originalReleaseDate
      primaryArtists {
        artistId
        artistName
        restricted
      }
      featuredArtists {
        artistId
        artistName
        restricted
      }
      productVersion
      releaseFormatType
      languageOfMetadata
      tracks {
        isrc
        sequenceNumber
        title
      }
      modifiedBy
      createdBy
      createdAt
      modifiedAt
      authors {
        createdBy {
          email
          employeeName
        }
        modifiedBy {
          email
          employeeName
        }
      }
      releaseDates {
        upc
        batchDeliveryId
        amazonReleaseDate
        youtubeReleaseDate
        youtubeCIDReleaseDate
        spotifyReleaseDate
        x5ReleaseDate
        createdAt
        createdBy
        modifiedAt
        modifiedBy
      }
      dspSpecificLabels {
        upc
        spotifyLabel
        x5Label
        youtubeLabel
        youtubeCIDLabel
        amazonLabel
      }
    }
    tracksOnProduct(upc: $upc) {
      isrc
      audioLocale
      isAvailableSeparately
      audioLanguage
      mainGenre
      mainSubGenre
      title
      label
      isParentalAdvisory
      isCover
      primaryArtists {
        artistId
        artistName
        restricted
      }
      featuredArtists {
        artistId
        artistName
        restricted
      }
      trackVersion
      publishers {
        isrc
        publisher {
          id
          publisherName
        }
        writer {
          id
          writerName
        }
      }
      contributors {
        name
        role
      }
      fileInfo {
        originalFileName
        fileName
      }
      pLineText
      pLineYear
      qsGenre
      qsGenreString
      qsCategory
      spotifyUri
      duration
      royaltyClaims {
        id
        providerId
        royaltyRate
      }
    }
  }
`;

export const GET_PRODUCTS_BY_UPCS_QUERY = gql`
  query productsByUpc($upcs: [Long!]!) {
    productsByUpc(upcs: $upcs) {
      upc
      name
      primaryArtists {
        artistId
        artistName
        restricted
      }
    }
  }
`;

export const GET_TRACKS_QUERY = gql`
  query getTracks($isrcs: [String!]!) {
    getTracks(isrcs: $isrcs) {
      isrc
      title
      primaryArtists {
        artistId
        artistName
        restricted
      }
    }
  }
`;

export const GET_FULL_TRACK_EXPENSIVE = gql`
  query track($isrc: String!) {
    track(isrc: $isrc) {
      isrc
      title
      primaryArtists {
        artistId
        artistName
        restricted
      }
      featuredArtists {
        artistId
        artistName
        restricted
      }
      audioLanguage
      audioLocale
      isCover
      label
      trackVersion
      mainGenre
      mainSubGenre
      pLineText
      pLineYear
      qsGenre
      qsCategory
      qsGenreString
      qsCategoryString
      spotifyUri
      publishers {
        isrc
        publisher {
          id
          publisherName
        }
        writer {
          id
          writerName
        }
      }
      contributors {
        contributorId
        name
        role
        isAlias
      }
      royaltyClaims {
        id
        providerId
        royaltyRate
        isrc
      }
    }
  }
`;

export const GET_FLAC_CONVERTED_TRACKS = gql`
  query getTracksConvertedToFLAC($isrcs: [String!]!) {
    getTracksConvertedToFLAC(isrcs: $isrcs)
  }
`;

export const GET_TRACKS_ON_PRODUCT_QUERY = gql`
  query tracksOnProduct($upc: Long!) {
    tracksOnProduct(upc: $upc) {
      isrc
      audioLocale
      isAvailableSeparately
      audioLanguage
      mainGenre
      mainSubGenre
      title
      label
      isParentalAdvisory
      isCover
      primaryArtist
      featuredArtists
      trackVersion
      publishers {
        isrc
        publisher {
          id
          publisherName
        }
        writer {
          id
          writerName
        }
      }
      contributors {
        name
        role
      }
      fileInfo {
        originalFileName
        fileName
      }
      pLineText
      pLineYear
      qsGenre
      qsGenreString
      qsCategory
      spotifyUri
      duration
      royaltyClaims {
        id
        providerId
        royaltyRate
      }
    }
  }
`;

export const UPSERT_PRODUCTS_MUTATION = gql`
  mutation upsertProduct($products: [ProductInput!]!) {
    upsertProducts(products: $products) {
      upc
    }
  }
`;

export const OVERRIDE_RELEASE_DATES_FOR_PRODUCTS_IN_BATCH_DELIVERY = gql`
  mutation overrideReleaseDatesForProductsInBatchDelivery(
    $productReleaseDates: ProductReleaseDatesInput!
  ) {
    overrideReleaseDatesForProductsInBatchDelivery(
      productReleaseDates: $productReleaseDates
    )
  }
`;

export const SAVE_PRODUCT_LABELS_MUTATION_FOR_BATCH = gql`
  mutation saveDspSpecificLabelsForBatch(
    $dspSpecificLabels: DspSpecificLabelsInput!
  ) {
    saveDspSpecificLabelsForBatch(dspSpecificLabels: $dspSpecificLabels)
  }
`;

export const GET_PRODUCT_RELEASE_DATES = gql`
  query getProductReleaseDates($upc: Long!) {
    productReleaseDates(upc: $upc) {
      upc
      batchDeliveryId
      spotifyReleaseDate
      x5ReleaseDate
      youtubeReleaseDate
      youtubeCIDReleaseDate
      amazonReleaseDate
    }
  }
`;

export const UPSERT_TRACKS_MUTATION = gql`
  mutation upsertTracks($tracks: [TrackMetadataInput!]!) {
    upsertTracks(tracks: $tracks) {
      isrc
      title
      royaltyClaims {
        id
        isrc
        providerId
        royaltyRate
      }
    }
  }
`;

export const INSERT_ATMOS_TRACKS = gql`
  mutation addAtmosTrackMappings(
    $atmosUPC: Long!
    $atmosTracks: [AtmosTrackInput!]!
  ) {
    addAtmosTrackMappings(atmosUPC: $atmosUPC, atmosTracks: $atmosTracks) {
      originalISRC
      atmosISRC
    }
  }
`;

export const GET_ATMOS_PRODUCT = gql`
  query getAtmosProduct($atmosUPC: Long!) {
    atmosProduct(atmosUPC: $atmosUPC) {
      atmosUPC
      originalUPC
      tracks {
        atmosISRC
        originalISRC
        fromMasterFile
        mixType
      }
      createdAt
      createdBy
    }
  }
`;

export const GET_ATMOS_PRODUCT_FROM_ORIGINAL_UPC = gql`
  query getAtmosProductFromOriginalProductUPC($originalUPC: Long!) {
    getAtmosProductFromOriginalProductUPC(originalUPC: $originalUPC) {
      atmosUPC
      originalUPC
      tracks {
        atmosISRC
        originalISRC
        fromMasterFile
        mixType
      }
      createdAt
      createdBy
    }
  }
`;
