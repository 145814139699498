import {
  ColumnDef,
  PaginationState,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  Table,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import _ from "lodash";
import React, { HTMLProps, useMemo, useState } from "react";
import { PageableResponse } from "../../types/Types";
import exportTableCSV from "../../utils/csvTableExporter/csvTableExporter";
import { DownloadIcon } from "../../utils/Icons";

import { Filter } from "./GeneralTableFilter";
import GeneralTableRightClickMenu, {
  RightClickMenuOption,
} from "./GeneralTableRightClickMenu";
import Pagination from "./GeneralTablePagination";

interface GeneralTableProps<T extends object> {
  onRowClick?: (row: Row<T>) => void;
  showPagination?: boolean;
  data?: PageableResponse<T> | T[];
  columns: ColumnDef<T, any>[];
  className?: string;
  manualPagination?: boolean;
  filtering?: boolean;
  initialSorting?: SortingState;
  columnVisibility?: VisibilityState;
  disableExport?: boolean;
  expandable?: boolean;
  expandOnRowClick?: boolean;
  enableRowSelection?: boolean;
  rightClickMenuOptions?: RightClickMenuOption<T>[];
  pageSize?: number;
}

const GeneralTable = <T extends object>(props: GeneralTableProps<T>) => {
  const {
    onRowClick,
    showPagination,
    className,
    data,
    columns,
    manualPagination = false,
    filtering,
    initialSorting,
    disableExport = false,
    expandOnRowClick = false,
    enableRowSelection = false,
    rightClickMenuOptions,
    pageSize: userDefinedPageSize,
  } = props;

  const [sorting, setSorting] = useState<SortingState>(initialSorting || []);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(
    getInitialPaginationState(props.data || [])
  );
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    props.columnVisibility || {}
  );
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  /**
   * Right click menu state
   */
  const [showRightClickMenu, setshowRightClickMenu] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize: userDefinedPageSize || pageSize,
    }),
    [pageIndex, pageSize, userDefinedPageSize]
  );

  const table = useReactTable<T>({
    data: data ? deriveData(data) : [],
    columns: enableRowSelection ? [createSelectColumn(), ...columns] : columns,
    onSortingChange: setSorting,
    getRowCanExpand: (row) => expandOnRowClick,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
    getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    enableRowSelection: enableRowSelection,
    onRowSelectionChange: setRowSelection,
    manualPagination: manualPagination,
    pageCount: data ? getTotalPages(data) : 0,
    state: {
      pagination,
      sorting,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className={className}>
      {showRightClickMenu && rightClickMenuOptions && (
        <GeneralTableRightClickMenu<T>
          menuPosition={position}
          options={rightClickMenuOptions}
          table={table}
          onBlur={() => setshowRightClickMenu(false)}
        />
      )}
      <table
        className="w-full divide-y divide-gray-200 table-fixed"
        onContextMenu={(e) => {
          if (rightClickMenuOptions) {
            e.preventDefault();
            setshowRightClickMenu(true);
            setPosition({ x: e.clientX, y: e.clientY });
          }
        }}
      >
        <thead className="font-semibold px-3 bg-neutral-trim">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="p-2 py-4 first:rounded-tl-xl last:rounded-tr-xl text-left text-neutral tracking-wider align-center"
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={`pl-2 py-3 text-left text-xs font-medium tracking-wider uppercase ${
                        header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : ""
                      }`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <i className="fas fa-sort-up pl-2 items-center" />,
                        desc: (
                          <i className="fas fa-sort-down pl-2 items-center" />
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                  {header.column.getCanFilter() && filtering ? (
                    <span className="text-xs font-medium tracking-wider">
                      <Filter column={header.column} table={table} />
                    </span>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-neutral-trim">
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={`cursor-pointer hover:bg-neutral-background h-12 overflow-hidden`}
              onMouseEnter={(e) => handleShiftSelection(e, row, table)}
              onMouseDown={(e) => handleShiftSelection(e, row, table)}
              onMouseUp={(e) => handleShiftSelection(e, row, table)}
              onClick={(e) => {
                e.stopPropagation();

                if (!e.shiftKey) {
                  const handler = row.getToggleSelectedHandler();
                  handler(e);
                }
                if (expandOnRowClick) {
                  row.toggleExpanded();
                }
                if (onRowClick) {
                  onRowClick(row);
                }
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="relative h-16 bg-neutral-trim rounded-b-xl px-3 flex items-center justify-center">
        {false && (
          <div>
            <label>Row Selection State:</label>
            <pre>{JSON.stringify(table.getState().rowSelection, null, 2)}</pre>
          </div>
        )}
        {showPagination && <Pagination table={table} />}

        {!disableExport && (
          <button className="secondary" onClick={() => exportTableCSV(table)}>
            <DownloadIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default GeneralTable;

function getInitialPaginationState<T>(
  data: PageableResponse<T> | T[]
): PaginationState {
  if ("pageable" in data) {
    return {
      pageIndex: data.pageable.pageNumber || 0,
      pageSize: data.pageable.pageSize || 20,
    };
  }
  return {
    pageIndex: 0,
    pageSize: 20,
  };
}

export function deriveData<T extends object>(
  data: PageableResponse<T> | T[]
): T[] {
  if ("content" in data) {
    return data.content;
  }
  return data;
}

function getTotalPages<T>(data: PageableResponse<T> | T[]): number {
  if ("totalPages" in data) {
    return data.totalPages;
  }

  return _.ceil(data.length / 20);
}

function handleShiftSelection(
  e: React.MouseEvent,
  row: Row<any>,
  table: Table<any>
) {
  if (e.shiftKey && table.options.enableRowSelection) {
    e.stopPropagation();
    e.preventDefault();
    const rows = table.getSelectedRowModel().rows;
    const firstSelectedRow = rows[0];

    if (e.type === "mousedown") {
      if (!row.getIsSelected()) {
        row.toggleSelected();
      }
    }

    if (e.type === "mouseup") {
      const firstRow = firstSelectedRow.index;
      const end = Math.max(firstRow, row.index);

      if (rows.length > 1) {
        firstSelectedRow.toggleSelected();
      }

      table.getRowModel().rows.forEach((row, i) => {
        if (row.index > firstRow && row.index <= end) {
          row.toggleSelected();
        }
      });
    }
  }
}

export function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " w-5 h-5 cursor-pointer"}
      {...rest}
    />
  );
}

declare module "@tanstack/react-table" {
  //allows us to define custom properties for our columns
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "text" | "range" | "select" | "react-select";
  }
}

const createSelectColumn = <T,>(): ColumnDef<T, any> => ({
  id: "select",
  header: ({ table }) => (
    <IndeterminateCheckbox
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler(),
      }}
    />
  ),
  cell: ({ row }) => (
    <div className="px-1">
      <IndeterminateCheckbox
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    </div>
  ),
});
