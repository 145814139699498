import { components } from "react-select";
import { CSSObject } from "@emotion/serialize";
import { ReactComponent as SearchIcon } from "../assets/icons/search_icon.svg";

export const REACT_SELECT_FORM_FIELD_STYLE = {
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: "#04856C",
    //padding: "10px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: "none",
    //"3px rgba($color: $dark-blue, $alpha: 0.8) solid",
    border: "1px rgba(15, 88, 110, 0.1) solid",
    height: "34px",
    borderRadius: "20px",
    marginRight: "1rem",
    backgroundColor: "rgba(241, 241, 241, 0.4) !important",
    "&:hover": {
      border: "1px solid #2d99ba !important",
    },
    "&:focus": {
      border: "1px solid #2d99ba !important",
    },
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    borderRadius: "20px",
    color: "#04856C",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    //display: "none",
    fontWeight: "200",
  }),
};

export const REACT_SELECT_CUSTOM_STYLE = {
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: "#04856C",
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    borderRadius: "20px",
    color: "#04856C",
    zIndex: 50,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: 0,
    boxShadow: "none",
  }),
  menuPortal: (base: any) => ({ ...base, color: "#04856C", zIndex: 9999 }),
};

export const REACT_SELECT_MULTI_STYLE = {
  option: (styles: CSSObject) => ({
    ...styles,
    color: "#0f586e",
    ":hover": {
      backgroundColor: "#0f586e",
      color: "white",
    },
  }),
  multiValue: (styles: CSSObject) => ({
    ...styles,
    padding: "1px",
    borderRadius: "0px",
    //borderLeft: "1px solid",
    //backgroundColor: "rgba(229, 230, 231, var(--tw-bg-opacity))",
    backgroundColor: "white",
    color: "#0f586e",
  }),
  multiValueLabel: (styles: CSSObject) => ({
    ...styles,
    fontSize: "18px",
    color: "#04856C",
  }),
  multiValueRemove: (styles: CSSObject) => ({
    ...styles,
    color: "#04856C",
    ":hover": {
      backgroundColor: "#DC2626",
      color: "#FFF",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: 0,
    boxShadow: "none",
    color: "#0f586e",
  }),
};

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className="icon button">
        <SearchIcon />
      </div>
    </components.DropdownIndicator>
  );
};

export const SearchIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <div>
        <SearchIcon />
      </div>
    </components.DropdownIndicator>
  );
};
