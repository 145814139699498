import React, { useEffect, useState } from "react";

import { Role, useAuth } from "../auth/AuthProvider";
import { useConfig } from "../config/ConfigProvider";
import NavbarSubMenu from "./NavbarSubMenu";

import {
  AnalyticsIcon,
  CollectionIcon,
  DeliveryIcon,
  ListIcon,
  LogoFull,
  LogoIcon,
  NotesIcon,
  SettingsIcon,
  UploadIcon,
  WorldIcon,
} from "../../utils/Icons";
import AdminVisibleWrapper from "../adminviewable/AdminVisibleWrapper";
import AvatarImage from "../AvatarImage";
import { NavbarListItem } from "./NavbarListItem";
import { IS_DEV_ENVIRONMENT } from "../../config/Constants";

const Navbar = React.memo(() => {
  const { isAuthorizedTo, isAuthenticated, signOut, currentUser } = useAuth();
  const { usingProductionData } = useConfig();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <nav
      className="group transition-width ease-in-out duration-150 sm:w-16 border-r hover:w-80 3xl:w-80 h-screen bg-white p-2 flex flex-col"
      onMouseEnter={(e) => e.stopPropagation()}
    >
      <div className="flex sm:flex-col 3xl:flex-row items-center justify-center gap-x-2 mb-4">
        <LogoIcon className="h-12" />
        {usingProductionData && IS_DEV_ENVIRONMENT ? (
          <p className="text-warning ">Prod</p>
        ) : null}
      </div>
      <div className="sm:hidden sm:group-hover:flex 3xl:flex 3xl:opacity-100 transition-opacity duration-75 ease-in-out flex-col justify-between h-full whitespace-nowrap">
        <ul className="px-2">
          <NavbarListItem
            link={{
              title: "Products",
              path: "/products",
              icon: <NotesIcon style={{ width: "24px" }} />,
            }}
          />
          <NavbarListItem
            link={{
              title: "Deliveries",
              path: "/deliveries",
              icon: <DeliveryIcon />,
            }}
          />

          <NavbarListItem
            link={{
              title: "Bulk",
              path: "/bulk",
              icon: <UploadIcon style={{ width: "24px" }} />,
            }}
          />

          <NavbarListItem
            link={{
              title: "Collections",
              path: "/collections",
              icon: <CollectionIcon style={{ width: "24px" }} />,
            }}
          />
          <NavbarListItem
            link={{
              title: "Landing Pages",
              path: "/landingpages",
              icon: <WorldIcon />,
            }}
          />
          {isAuthorizedTo(
            Role.ADMIN,
            Role.FINANCIAL_READ,
            Role.SUPER_ADMIN
          ) && (
            <NavbarSubMenu
              menuName="financialMenu"
              empty
              mainLink={{
                title: "Financial",
                path: "/financial",
                icon: (
                  <div className="flex justify-center items-center">
                    <i
                      className="fas fa-dollar-sign text-interactive text-lg pl-1"
                      style={{ width: "24px" }}
                    />
                  </div>
                ),
              }}
              linkList={[
                { title: "Settlements", path: "/financial/settlements" },
                {
                  title: "Statements and Invoices",
                  path: "/financial/statements-and-invoices",
                },

                { title: "Accounting", path: "/financial/accounting" },
                { title: "Contracts", path: "/financial/contracts" },
              ]}
            />
          )}
          <NavbarSubMenu
            menuName="statistics"
            empty={true}
            mainLink={{
              title: "Statistics",
              path: "/statistics",
              icon: <AnalyticsIcon style={{ width: "24px" }} />,
            }}
            linkList={[
              { title: "Artist Statistics", path: "/statistics/artists" },
              { title: "Track Statistics", path: "/statistics/tracks" },
            ]}
          />
          <NavbarSubMenu
            menuName="toolsMenu"
            empty={true}
            mainLink={{
              title: "Tools",
              path: "/tools",
              icon: <SettingsIcon style={{ width: "24px" }} />,
            }}
            linkList={[{ title: "BlobChecker", path: "/tools/blobchecker" }]}
          />
          <NavbarSubMenu
            menuName="administrationMenu"
            empty={true}
            mainLink={{
              title: "Administration",
              path: "/administration",
              icon: <ListIcon />,
            }}
            linkList={[
              {
                title: "Categories & Genres",
                path: "/administration/categoriesandgenres",
              },
              {
                title: "Artists",
                path: "/administration/artists",
              },
              {
                title: "Providers",
                path: "/administration/providers",
              },
              {
                title: "Writers",
                path: "/administration/writers",
              },
              {
                title: "Publishers",
                path: "/administration/publishers",
              },
            ]}
          />
        </ul>
        <ul>
          <AdminVisibleWrapper>
            <NavbarSubMenu
              menuName="adminMenu"
              empty={true}
              mainLink={{
                title: "Admin",
                path: "/admin",
                icon: <NotesIcon />,
              }}
              linkList={[{ title: "Dev menu", path: "/devtools" }]}
            />
          </AdminVisibleWrapper>

          <li className="flex py-2 gap-x-2 items-center text-interactive hover:text-interactive-hover hover:bg-interactive-background rounded-lg cursor-pointer">
            <AvatarImage
              className="w-10 h-10"
              imageUrl={currentUser?.photoURL}
            />
            <span onClick={signOut}>Log out</span>
          </li>
        </ul>
      </div>
    </nav>
  );
});

export default Navbar;
