import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import PublisherPage from "../components/administration/publishers/PublisherPage";
import PublishersAdministration from "../components/administration/publishers/PublishersAdministration";
import WriterPage from "../components/administration/writers/WriterPage";
import WritersAdministration from "../components/administration/writers/WritersAdministration";
import { Role, useAuth } from "../components/auth/AuthProvider";
import BlobChecker from "../components/blobchecker/BlobChecker";
import Navbar from "../components/navbar/Navbar";
import AuthorizedRoute from "./AuthorizedRoute";
import WriterTracksTab from "../components/administration/writers/WriterTracksTab";
import AudioPlayer from "../components/audioplayer/AudioPlayer";

const ProviderDetailsTab = React.lazy(
  () => import("../components/administration/providers/ProviderDetailsTab")
);

const ProviderArtistTab = React.lazy(
  () => import("../components/administration/providers/ProviderArtistTab")
);

const ArtistDetailsTab = React.lazy(
  () => import("../components/administration/artists/ArtistDetailsTab")
);

const ArtistTracksTab = React.lazy(
  () => import("../components/administration/artists/ArtistTracksTab")
);

const WriterDetailsTab = React.lazy(
  () => import("../components/administration/writers/WriterDetailsTab")
);

const PublisherDetailsTab = React.lazy(
  () => import("../components/administration/publishers/PublisherDetailsTab")
);

const PublisherWritersTab = React.lazy(
  () => import("../components/administration/publishers/PublisherWriterTab")
);

const CategoriesAndGenresAdministration = React.lazy(
  () =>
    import(
      "../components/administration/categoriesandgenres/CategoriesAndGenresAdministration"
    )
);

const ArtistsAdministration = React.lazy(
  () => import("../components/administration/artists/ArtistsAdministration")
);

const SettlementsStatusMatrix = React.lazy(
  () => import("../components/financial/SettlementStatusMatrix")
);

const SettlementsOverview = React.lazy(
  () => import("../components/financial/SettlementsOverview")
);

const TrackStatistics = React.lazy(
  () => import("../domain/statistics/tracks/TracksStatisticsView")
);

const ArtistStatistics = React.lazy(
  () => import("../domain/statistics/artists/ArtistStatisticsView")
);

const ProvidersAdministration = React.lazy(
  () => import("../components/administration/providers/ProvidersAdministration")
);

const StatementsAndInvoices = React.lazy(
  () => import("../domain/statements-and-invoices/StatementsAndInvoices")
);

const SettlementView = React.lazy(
  () => import("../views/financial/SettlementsView")
);

const AccountingOverview = React.lazy(
  () => import("../components/forms/accounting/AccountingOverview")
);
const AccountingView = React.lazy(
  () => import("../components/forms/accounting/AccountingOverview")
);

const ContractsView = React.lazy(
  () => import("../components/contracts/ContractsOverview")
);

const ContractPage = React.lazy(
  () => import("../components/contracts/ContractPage")
);

const CollectionPage = React.lazy(
  () => import("../components/collections/CollectionPage")
);

const GlobalMessageDisplay = React.lazy(
  () => import("../components/GlobalMessageDisplay/GlobalMessageDisplay")
);

const CollectionOverviewTab = React.lazy(
  () => import("../components/collections/tabs/CollectionDetailsTab")
);

const CollectionsList = React.lazy(
  () => import("../components/collections/CollectionsList")
);

const DangerZone = React.lazy(
  () => import("../components/collections/tabs/CollectionsDangerZoneTab")
);

const DevToolsView = React.lazy(() => import("../views/tools/DevToolsView"));

const ProductView = React.lazy(
  () => import("../components/products/ProductView")
);

const LandingPageView = React.lazy(
  () => import("../views/landingpages/LandingPageView")
);

const LoginForm = React.lazy(() => import("../components/auth/LoginForm"));

const ProviderPage = React.lazy(
  () => import("../components/administration/providers/ProviderPage")
);

const ArtistPage = React.lazy(
  () => import("../components/administration/artists/ArtistPage")
);

const LandingPagePage = React.lazy(
  () => import("../components/landingpages/LandingpagePage")
);

const DeliveriesView = React.lazy(
  () => import("../views/products/deliveries/DeliveriesView")
);

const BatchDeliveries = React.lazy(
  () => import("../components/products/deliveries/BatchDeliveries")
);

const BatchDeliveryPage = React.lazy(
  () => import("../components/products/deliveries/page/BatchDeliveryPage")
);

const ProductsOverview = React.lazy(
  () => import("../views/products/ProductsOverview")
);
const AnalyticsView = React.lazy(
  () => import("../views/analytics/AnalyticsView")
);

const StepperBulkUploadView = React.lazy(
  () => import("../views/bulkupload/StepperBulkUploadView")
);

const CollectionsView = React.lazy(
  () => import("../views/collections/CollectionsView")
);

const CollectionExportsTab = React.lazy(
  () => import("../components/collections/tabs/CollectionExportsTab")
);

const AppRouter = React.memo(() => {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      <div className="flex">
        <Navbar />
        <React.Suspense fallback={<div />}>
          <GlobalMessageDisplay />
        </React.Suspense>
        <div className="w-full h-screen overflow-y-auto">
          {/**to fit the audio player */}
          <React.Suspense fallback={<div />}>
            <Routes>
              <Route element={<AuthenticatedOutlet />}>
                <Route path="/products">
                  <Route index element={<ProductsOverview />} />
                  <Route path=":upc" element={<ProductView />} />
                </Route>
                <Route element={<DeliveriesView />}>
                  <Route path="/deliveries">
                    <Route index element={<BatchDeliveries />} />
                    <Route
                      path=":batchDeliveryId"
                      element={<BatchDeliveryPage />}
                    />
                  </Route>
                </Route>

                <Route path="/analytics" element={<AnalyticsView />} />

                <Route path="/bulk" element={<StepperBulkUploadView />} />

                <Route path="/collections" element={<CollectionsView />}>
                  <Route index element={<CollectionsList />} />
                  <Route
                    path="/collections/:collectionId"
                    element={<CollectionPage />}
                  >
                    <Route index element={<CollectionOverviewTab />} />
                    <Route path="exports" element={<CollectionExportsTab />} />
                    <Route path="dangerzone" element={<DangerZone />} />
                  </Route>
                </Route>

                <Route
                  path="/financial"
                  element={
                    <AuthorizedRoute
                      roles={[Role.FINANCIAL_READ, Role.SUPER_ADMIN]}
                    />
                  }
                >
                  <Route
                    path="/financial/settlements"
                    element={<SettlementView />}
                  >
                    <Route index element={<SettlementsOverview />} />
                    <Route
                      path="status"
                      element={<SettlementsStatusMatrix />}
                    />
                  </Route>

                  <Route
                    path="statements-and-invoices"
                    element={<StatementsAndInvoices />}
                  />

                  <Route
                    path="/financial/accounting"
                    element={<AccountingOverview />}
                  />
                  <Route path="accounting" element={<AccountingView />} />

                  <Route path="contracts">
                    <Route index element={<ContractsView />} />
                    <Route path=":contractId" element={<ContractPage />} />
                  </Route>
                </Route>
                <Route path="/statistics">
                  <Route path="artists" element={<ArtistStatistics />} />
                  <Route path="tracks" element={<TrackStatistics />} />
                </Route>

                <Route path="/" element={<Navigate to="/products" />} />
                <Route path="landingpages">
                  <Route index element={<LandingPageView />} />
                  <Route path=":landingPageId" element={<LandingPagePage />} />
                </Route>

                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/tools">
                  <Route path="blobchecker" element={<BlobChecker />} />

                  <Route
                    path="categoriesandgenres"
                    element={<CategoriesAndGenresAdministration />}
                  />
                </Route>
                <Route path="/administration">
                  <Route
                    path="categoriesandgenres"
                    element={<CategoriesAndGenresAdministration />}
                  />
                  <Route path="artists">
                    <Route index element={<ArtistsAdministration />} />
                    <Route path=":artistId" element={<ArtistPage />}>
                      <Route index element={<ArtistDetailsTab />} />
                      <Route path="tracks" element={<ArtistTracksTab />} />
                    </Route>
                  </Route>
                  <Route path="providers">
                    <Route index element={<ProvidersAdministration />} />
                    <Route path=":providerId" element={<ProviderPage />}>
                      <Route index element={<ProviderDetailsTab />} />
                      <Route path="artists" element={<ProviderArtistTab />} />
                    </Route>
                  </Route>
                  <Route path="writers">
                    <Route index element={<WritersAdministration />} />
                    <Route path=":writerId" element={<WriterPage />}>
                      <Route index element={<WriterDetailsTab />} />
                      <Route path="tracks" element={<WriterTracksTab />} />
                    </Route>
                  </Route>
                  <Route path="publishers">
                    <Route index element={<PublishersAdministration />} />
                    <Route path=":publisherId" element={<PublisherPage />}>
                      <Route index element={<PublisherDetailsTab />} />
                      <Route path="writers" element={<PublisherWritersTab />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="/devtools" element={<DevToolsView />} />
              </Route>

              <Route
                path="/login"
                element={
                  isAuthenticated ? <Navigate to="/products" /> : <LoginForm />
                }
              />
            </Routes>
          </React.Suspense>
          <AudioPlayer />
        </div>
      </div>
    </BrowserRouter>
  );
});

const AuthenticatedOutlet = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Outlet /> : <Navigate to={"/login"} />;
};

export default AppRouter;
